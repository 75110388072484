/**
 * shared functions for all the JS
 */

function _(el){
	return document.getElementById(el);
}

function __(el){
	return document.querySelectorAll(el);
}
/**
 * Get element by class name
 * @return {[type]} [description]
 */
function ___(el){
//	debug(el);
	el=el.replace(/\./g, " ");
//	debug(el);
	el=el.trim();
//	debug(el);
//	debug(document.getElementsByClassName(el));
	return document.getElementsByClassName(el);
}

function lastInstanceOfClass(el){
	return ___(el)[___(el).length-1];
}

function disableButton(button,options)
{
	button = button||false;
	options = options||false;

	var style='style="';
	if(options){
		if(options.color){
			style+='color:'+options.color+'; ';
		}
	}
	style+='"';

	if(button){
		try {
			button.disabled=true;
				button.innerHTML='<i class="fas fa-circle-notch fa-spin cPrimary" '+style+'></i>';
			button.style.opacity=options.fadeAmount?options.fadeAmount:'0.5';
			if(options){
				if(options.replaceText){
					button.innerHTML+=' '+options.replaceText;
				}
			}
		}catch(e){
			debug(e);
		}
	}
}

function enableButton(button,options){
	button = button || false;

	if(button){
		setTimeout(function resettingButton(){
			try {
				button.disabled=false;
				button.style.opacity='1';
				if(options){
					if(options.replaceText){
						button.innerHTML=options.replaceText;
					}else{
						button.innerHTML='';
					}
				}
			} catch(e) {
				debug(e);
			}
		}, 250);
	}
}

function show(el)
{
	$(el).show();
}

function hide(el)
{
	$(el).hide();
}

function post(method,endpoint,endPointAdds,dataObj,onFail,onSuccess,options,authType){
	debug(
		'onFail function: '+onFail.name,
		'onSuccess function: '+onSuccess.name
		);

	var auth;
	options=options||false;
	endPointAdds=endPointAdds||false;
	method=method||'GET';
	method=method.toUpperCase();
	endpoint=endpoint||false;
	dataObj=dataObj||false;
	onSuccess=onSuccess||false;
	onFail=onFail||false;
	authType=authType||false;
	if(!authType){
		authType='Bearer ';
	}

	if(options){
		debug('Post function options:',options);
		options.showLoader=options.showLoader||false;
		options.timeout=options.timeout||30000;
		options.pass=options.pass||false; //to be passed to success or fail callback
	}

	//delay hack to make sure the loader shows
/*	if(options.showLoader){
		if($('#loader')){
			setTimeout(function(){
				$('#loader').show();
			}, 1);
		}
	}
*/
	if(options.showLoader){
		try {
			$('#loader').show();
		} catch(e) {
			debug(e);
		}
	}

	if(endPointAdds){
		for(var i=0;i<endPointAdds.length;i++){
			endpoint+='/'+endPointAdds[i];
		}
	}

	//by default use the api key that is restricted to just registering and logging in, else use the user key that is obtained after logging in
	auth = app.user.token?app.user.token:app.api.token;


	var tempDataObj = JSON.parse(JSON.stringify(dataObj)); //hide the pw from public view
	if(tempDataObj.password){
		tempDataObj.password='******';
	}
	debug('tempDataObj:',tempDataObj);
	debug(method,endpoint,'Authorization: Bearer '+auth);

	$.ajax({
		headers: {
			"Authorization":authType+auth
		},
		type: method,
		url: endpoint,
		dataType: "json",
		data: dataObj,
		timeout: options.timeout,
		success: function(obj){
			if (obj && obj.status === 'OK'){
				debug(method+' response:',obj);
				if(onSuccess){
					if(options.pass){
						onSuccess(obj,options.pass);
					}
					else{
						onSuccess(obj);
					}
				}
				if(options.showLoader){
					if(_('loader')){
						_('loader').style.display = 'none';
					}
				}
			}
			else{
				debug('Request failed',obj);
				if(onFail){
					onFail(obj);
				}
				if(options.showLoader){
					if(_('loader')){
						_('loader').style.display = 'none';
					}
				}
			}
		},
		error: function(e,exception){
			debug('Request error: '+e.responseText,e,exception);
			var errorMsg=e.responseText?e.responseText:'Something went wrong.  Please refresh the page and try again.';
			debug(onFail);
			if(onFail){
				onFail(errorMsg);
			}
			if(options.showLoader){
				if(_('loader')){
					_('loader').style.display = 'none';
				}
			}
		}
	});
}

function showError(msg,title)
{
	debug('Showing error',msg,title);
	alert(msg);
}

function debug(...args)
{
	if(args.length>1)
	{
		for(var i=0;i<args.length;i++)
		{
			if(typeof args[i]!=='object')
			{
				args[i]+='\n';
			}
		}
	}
	if(app.debug)
	{
		if(typeof(console) !== 'undefined')
		{
			console.log(...args);
		}
	}
}
$(document).ready(function(){
	var scrollThresh=50;
	var nav = $('.nav');
	var navList = $('.navList');
	var defaultNavHeight='50px';

	var navAction = {
		open:function(){
			nav.removeClass('collapsed');
			nav.addClass('expanded');
		},
		close:function(){
			nav.removeClass('expanded');
			nav.addClass('collapsed');
		}
	};

	$('.navSlideButton').click(function toggleMobileNav(){
		if(nav.hasClass('collapsed')){
			navAction.open();
		}else{
			navAction.close();
		}
	});

	//check initially
	changeColors();
	checkNavSize();
	//check on scroll
	$(window).scroll(function() {
		changeColors();
		checkNavSize();
	});
	$(window).resize(function() {
		changeColors();
		checkNavSize();
	});

	function checkNavSize(){
		if($(this).width()<=768){
			if(!navList.hasClass('mobile')){
				navList.addClass('mobile');
				navAction.close();
			}
		}else{
			navList.removeClass('mobile');
			navAction.close();
		}
	}

    function changeColors(){
		if($(this).scrollTop() > scrollThresh) { // this refers to window
			if(!nav.hasClass('scrolled')){
				nav.addClass('scrolled');
			}
		}else{
			nav.removeClass('scrolled');
		}
	}

	$('.fastLink,a[href^="#"]').click(function(){

		var moveTo = $(this).data("fastlink"); //data must be lowercased
		$('html, body').stop(true);
		$('html, body').animate({
		scrollTop: $('#'+moveTo).offset().top
		}, 500);
		event.preventDefault();
		navAction.close();
		return false;
	});
});
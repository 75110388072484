var siteKey = '6Lco4p4UAAAAAAkm5qQEQwLau_33PvgccOKVlpeZ';
   // when form is submit
$('#submitBtn').click(function(event) {
    event.preventDefault();
	console.log('sending email...');

	$('#submitBtn').html('<i class="fas fa-spin fa-spinner-third"></i> Sending');
	$('#submitBtn').css({
		opacity: '.5'
	});
	$('#submitBtn').prop("disabled",true);

    var postObject = {
    	firstName:$("#firstName").val(),
    	lastName:$("#lastName").val(),
    	email:$("#email").val(),
    	phoneNumber:$("#phoneNumber").val(),
    	company:$("#company").val(),
    	description:$("#description").val()
    };
	console.log('getting captcha ready...');

    // wait for recaptcha to be ready
    grecaptcha.ready(function() {
	console.log('captcha ready...');
        // do request for recaptcha token
        // response is promise with passed token
        grecaptcha.execute(siteKey, {action: 'contact'}).then(function(token) {
            // add token to form
            $('#recaptchaForm').prepend('<input type="hidden" name="g-recaptcha-response" value="' + token + '">');
            //add token to object
            postObject.token=token;
			console.log('post obj',postObject);
            $.post("includes/recaptcha.php",postObject, function(result) {
				console.log(result);
				if(result.success) {
                        alert('Email sent!');
                        window.location.href="index.php?sent=1";
                } else {
                    	$('#formErrors').html('');
                	for(var i=0;i<result.errors.length;i++){
                    	$('#formErrors').append(result.errors[i]+'<br>');

                    	$('#submitBtn').html('send');
                    	$('#submitBtn').css({
                    		opacity: '1'
                    	});
                    	$('#submitBtn').prop("disabled",false);
                	}
                }
             });
        });
    });
});